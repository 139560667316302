import React, { useEffect, useState } from "react";
import CampaignModal from "./Modal";
import "./style.css";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useParams } from "react-router-dom";

const Campaign = () => {
  const params = useParams();
  const [userData, setuserData] = useState({});
  const [Campaigns, setCampaigns] = useState([]);
  // const GetUserData = async () => {
  //   const decript_data = async (payload) => {
  //     const GHL_APP_SSO_KEY = "fd791479-ff4f-48b2-a137-c849d71cefc4";
  //     const data = CryptoJS.AES.decrypt(payload, GHL_APP_SSO_KEY).toString(
  //       CryptoJS.enc.Utf8
  //     );

  //     console.log(JSON.parse(data), "user seesion");
  //     await setuserData(JSON.parse(data));
  //     onLoad(JSON.parse(data));
  //   };
  //   const key = await new Promise((resolve) => {
  //     window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*");
  //     console.log(
  //       window.parent.postMessage({ message: "REQUEST_USER_DATA" }, "*"),
  //       "test data"
  //     );
  //     window.addEventListener("message", ({ data }) => {
  //       console.log(data, "window.addEventListener");
  //       if (data.message === "REQUEST_USER_DATA_RESPONSE") {
  //         decript_data(data.payload);
  //         // return data.payload
  //       } else {
  //         // return 'databklf'
  //       }
  //     });
  //   });
  // };
  const GetCampaigns = async () => {
    let response;
    try {
      response = await axios.get(
        "https://marketplace.ghl.store/v1/tools/spinthewheel/getallcampaigns?locationId=" +
          params?.locationId
      );

      const data = response.data.data;
      setCampaigns(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onLoad = async (user_data) => {
    GetCampaigns();
  };
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <div className="table-card">
      <div className="table-header">
        <CampaignModal handleRefresh={GetCampaigns} />
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>No of wins spins</th>
            <th>No of lose spins</th>
            <th>total spins</th>
          </tr>
        </thead>
        <tbody>
          {Campaigns?.map((campaign) => (
            <tr>
              <td>{campaign?.name}</td>
              <td>5</td>
              <td>4</td>
              <td>9</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default Campaign;
