import React, { useEffect, useState } from "react";
// import "./style.css";
import { useParams } from "react-router-dom";
import axios from "axios";
const CampaignForm = ({ handleRefresh, onClose }) => {
  const [sliderValue, setSliderValue] = useState(50);
  const [processing, setprocessing] = useState(false);
  const params = useParams();
  const app_id = "653a8ca5623dcf63fb0740d9";
  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  const ImageUpload = async (file) => {
    const form_data = new FormData();
    form_data.append("image", file, file.name);
    console.log(form_data.get("image"), "fferg");
    const image_url = await axios
      .post(
        `https://api.alltheapps.io/api/v1/utils/upload/image?location_id=${params.locationId}&app_id=${app_id}`,
        form_data
      )
      .then((response) => {
        return response.data.data.data;
      });
    return image_url;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setprocessing(true);
    const win_item_image = await ImageUpload(e.target.win_item_image.files[0]);
    const loose_item_image = await ImageUpload(
      e.target.loose_item_image.files[0]
    );
    const payload = {
      locationId: params?.locationId,
      app_id: app_id,
      win_percentage: parseInt(sliderValue),
      loss_percentage: 100 - sliderValue,
      loose_item_title: e.target.loose_item_title.value,
      win_item_title: e.target.win_item_title.value,
      name: e.target.name.value,
      loose_item_image: loose_item_image,
      win_item_image: win_item_image,
      loose_item_button_link: e.target.loose_item_button_link.value,
      no_of_spin: e.target.no_of_spin.value,
      win_item_button_link: e.target.win_item_button_link.value,
    };
    console.log(payload, "payload");
    try {
      const response = await fetch(
        "https://marketplace.ghl.store/v1/tools/spinthewheel/create_form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
        setprocessing(false);
      }

      const data = await response.json();
      console.log("Server response:", data);
      setprocessing(false);
      handleRefresh();
      onClose();
    } catch (error) {
      console.error("Error during fetch:", error.message);
      setprocessing(false);
    }
  };
  return (
    <div>
      <div className="form-container">
        <form className="beautiful-form" onSubmit={handleSubmit}>
          <label htmlFor="name" className="form-label">
            Campaign Name
          </label>
          <input type="text" name="name" className="form-input" required />
          <label htmlFor="loose_item_title" className="form-label">
            Loose item title
          </label>
          <input
            type="text"
            name="loose_item_title"
            className="form-input"
            required
          />
          <label htmlFor="win_item_title" className="form-label">
            Win item title
          </label>
          <input
            type="text"
            name="win_item_title"
            className="form-input"
            required
          />
          <label htmlFor="win_item_button_link" className="form-label">
            win item button link
          </label>
          <input
            type="text"
            name="win_item_button_link"
            className="form-input"
            required
          />
          <label htmlFor="loose_item_button_link" className="form-label">
            loose item button link
          </label>
          <input
            type="text"
            name="loose_item_button_link"
            className="form-input"
            required
          />

          <label htmlFor="loose_item_image" className="form-label">
            loose item image link
          </label>
          <input
            type="file"
            name="loose_item_image"
            className="form-input"
            required
          />
          <label htmlFor="win_item_image" className="form-label">
            loose item image link
          </label>
          <input
            type="file"
            name="win_item_image"
            className="form-input"
            required
          />
          <label htmlFor="win_item_image" className="form-label">
            No of spins
          </label>
          <input
            type="number"
            name="no_of_spin"
            className="form-input"
            required
          />
          <label htmlFor="slider" className="slider-label">
            Winning Percentage
          </label>
          <input
            type="range"
            name="slider"
            min="0"
            max="100"
            value={sliderValue}
            onChange={handleSliderChange}
            className="slider-input"
          />
          <p className="slider-value">Percentage: {sliderValue}%</p>
          <button disabled={processing} type="submit" className="form-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

const CampaignModal = ({ handleRefresh }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div>
      <button onClick={openModal}>Create</button>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            {/* Add your modal content here */}
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <CampaignForm handleRefresh={handleRefresh} onClose={closeModal} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignModal;
