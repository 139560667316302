import React, { useState, useEffect } from "react";
import WheelComponent from "./weel";
import "react-wheel-of-prizes/dist/index.css";
import axios from "axios";
import "./styles.css";
import IMAGES from "./assets";
import TrPortal from "./portal";
import Confetti from "react-confetti";
import { useParams } from "react-router-dom";

const Loader = () => <div>Loading...</div>;

const SpinWheel = () => {
  const [loading, setLoading] = useState(true);
  const [portal, setPortal] = useState(false);
  const [show, setShow] = useState(false);
  const params = useParams();
  const [data, setData] = useState({});
  const [result, setResult] = useState({});
  const [campaign_data, setcampaign_data] = useState({});

  const [spinResults, setSpinResults] = useState([]);
  const [winImage, setWinImage] = useState(null);
  const [loseImage, setLoseImage] = useState(null);
  const [hasWon, setHasWon] = useState(false);
  const [win, setWin] = useState("");
  const [wheelData, setWheelData] = useState({
    segments: [],
    segColors: [],
  });
  const app_id = "653a8ca5623dcf63fb0740d9";

  // const [lastResult, setLastResult] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      let response;
      try {
        response = await axios.get(
          "https://marketplace.ghl.store/v1/tools/spinthewheel/filter_cammpaign?_id=" +
            params?.campaign_id
        );
        console.log(response, "response");
        const data = response.data.data;
        // const _id = response.data.data._id;
        setData(data);
        setcampaign_data(data);
        const apiSegments =
          [
            data.win_item_title,
            data.win_item_title,
            data.win_item_title,
            data.win_item_title,
            data.win_item_title,
            data.win_item_title,
          ] || [];
        const win = data.win_item_title;
        const loose = data.loose_item_title;
        setWin(win);
        const percentageWin = data.win_percentage;
        const totalSegments = 6;

        const numberOfWinSegments = Math.ceil(
          (percentageWin / 100) * totalSegments
        );

        let arrayOfStrings = [];
        for (let i = 0; i < totalSegments; i++) {
          const isWinSegment = i < numberOfWinSegments;
          const segmentValue = isWinSegment ? win : loose;
          arrayOfStrings.push(segmentValue);
        }

        setWinImage(data.win_item_image);
        setLoseImage(data.loose_item_image);

        setWheelData({
          segments: arrayOfStrings,
          segColors: weelColors(apiSegments.length),
        });

        const generatedObjIndex = {};
        apiSegments.forEach((title, index) => {
          generatedObjIndex[title.split(" ").join("")] = index + 1;
        });

        // Uncomment the line below if you need to use setObjIndex
        // setObjIndex(generatedObjIndex);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  const updateData = async (win) => {
    const payload = {
      campaign_name: campaign_data.name,
      location_id: params.locationId,
      app_id: app_id,
      campaign_id: campaign_data._id,
      contactId: params.contactId,
      spin_status: hasWon ? "win" : "lose",
      type: hasWon ? "win_spin_wheel" : "lose_spin_wheel",
    };
    try {
      console.log(payload, "payload");

      const res = await axios.post(
        "https://marketplace.ghl.store/v1/spinwheeltrigger/send/" +
          params.locationId,
        payload
      );
      console.log(res.data, "testing");
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
  const weelColors = (numSegments) => {
    let arr = [];
    let colors = ["#EE4040", "#F0CF50", "#815CD1", "#3DA5E0", "#34A24F"];
    for (let i = 0; i < numSegments; i++) {
      let color = colors[i % colors.length];
      arr.push(color);
    }
    return arr;
  };

  const onFinished = async (winner) => {
    const joinedWinner = winner.split(" ").join("");

    console.log("joinedWinner:", joinedWinner);
    console.log("Win Image:", winImage);
    console.log("Lose Image:", loseImage);
    console.log(winner, "ali");
    setPortal(false);
    setShow(joinedWinner);

    setSpinResults([...spinResults, joinedWinner]);
    if (joinedWinner === win.split(" ").join("")) {
      setHasWon(true);
    } else {
      setHasWon(false);
    }

    // Store the last result
    // setLastResult(joinedWinner);
    updateData(winner);
  };

  const onTryAgain = () => {
    setHasWon(false);
    setShow(false);
  };

  const onSegmentClick = (segment) => {
    console.log(`Segment clicked: ${segment}`);
  };

  return (
    <div
      className="spin_wheel_body"
      style={{
        display: "flex",
        justifyContent: "center",
        background: `url(${IMAGES.background})`,
      }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          {show && <Confetti width={1600} height={100} />}
          <WheelComponent
            segments={wheelData.segments}
            segColors={wheelData.segColors}
            winningSegment={"8"}
            onFinished={onFinished}
            primaryColor="gray"
            contrastColor="white"
            buttonText="Spin"
            isOnlyOnce={true}
            onClick={onSegmentClick}
          />
          {portal && <TrPortal />}
          {show && (
            <div className="box">
              <div className="imageBox">
                <img src={hasWon ? winImage : loseImage} alt="" />
              </div>
              <h2 className="titleWin"> {show} !!!</h2>
              <div className="closeContainer">
                <button
                  className="closepankaj"
                  onClick={() => {
                    onTryAgain();
                  }}
                >
                  {hasWon ? (
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href={campaign_data?.win_item_button_link}
                    >
                      okay
                    </a>
                  ) : (
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href={campaign_data?.win_item_button_link}
                    >
                      okay
                    </a>
                  )}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SpinWheel;
