import React from "react";
import { Route, Routes } from "react-router-dom";
import SpinWheel from "./spinwheel";
import Campaign from "./Campaign/index";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/:locationId" element={<Campaign />} />
        <Route
          path="/spin/:locationId/:contactId/:campaign_id"
          element={<SpinWheel />}
        />
      </Routes>
    </>
  );
};

export default App;
